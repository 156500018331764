import createStore from 'unistore';
import { localStorageUtils } from 'utils';

const quality = localStorageUtils.getItem('quality') || 'hd';
let visitedAlbums;
let saveVisitedAlbum;
try {
    const params = new URLSearchParams(document.location.search.substring(1));
    const embedder = params.get('embedder');
    const commonKey = 'visitedAlbums';
    const localStorageKey = embedder === null ? commonKey : `${embedder}-${commonKey}`;

    const localAlbums = localStorageUtils.getItem(localStorageKey);
    let visitedAlbumsObject;
    try {
        visitedAlbumsObject = JSON.parse(localAlbums) || {};
    } catch(e) {
        visitedAlbumsObject = {};
    }
    visitedAlbums = Object.entries(visitedAlbumsObject).map(([id, name]) => ({ id, name }));
    visitedAlbums.sort((a, b) => a.name.localeCompare(b.name));

    let rawSaveVisitedAlbum = localStorageUtils.getItem('saveVisitedAlbum');
    saveVisitedAlbum = rawSaveVisitedAlbum === 0 ? false : true;
} catch (err) {
    visitedAlbums = [];
}

export const store = createStore({
    currentUrl: '/',
    imageArray: {},
    rawImageArray: {},
    darkThemeEnabled: true,
    saveVisitedAlbum,
    selectedImage: null,
    album: null,
    quality,
    qualityAuto: true,
    visitedAlbums,
    isFullscreen: false,
    user: {},
});
