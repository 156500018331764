const PHOTOS_HOST = 'https://api.rumesh.photos';
import { localStorageUtils } from 'utils';


function getQualityBaseImages(rawImages, quality) {
    return rawImages.photos.map((photo) => {
        return {...photo, url: photo.url.replace('[QUALITY]', quality)}
    })
}
function getQualityBasedImageArray(rawImages, quality) {
    return {
        ...rawImages,
        photos: getQualityBaseImages(rawImages, quality),
    };
}

function saveVisitedAlbumToStorage(visitedAlbums) {
    const visitedAlbumsMap = visitedAlbums.reduce((map, album) => {
        if (!map[album.id]) map[album.id] = album.name;
        return map;
    }, {});
    localStorageUtils.setItem('visitedAlbums', JSON.stringify(visitedAlbumsMap));
}

function getUserFromApi(user) {
    return fetch(`${PHOTOS_HOST}/user`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
    }).then((res) => {
        return res.json();
    })
    .catch((error) => {
        console.error('getUserFromApi', error);
        return {};
    });
}


let fetchOnGoing = null;
export const actions = (store) => ({
    getPhotos(state) {
        if (state.album === null) return state;

        // TODO cancel fetch on going if not the same url / album
        if (fetchOnGoing !== null) return fetchOnGoing;

        fetchOnGoing = fetch(`${PHOTOS_HOST}/album/${state.album}`, { mode: 'cors', credentials: 'include' })
            .then((res) => {
                fetchOnGoing = null;
                return res.json();
            })
            .then((resJson) => {
                // the variable 'state' above could now be old,
                // better get a new one from the store
                const upToDateState = store.getState();
                if (resJson?.photos?.length > 0) {
                    // do the preview once
                    resJson.preview = getQualityBaseImages(resJson, 'preview');
                    const newVisitedAlbums = [].concat(upToDateState.visitedAlbums);
                    if (upToDateState.saveVisitedAlbum) {
                        let existingIndex = newVisitedAlbums.findIndex((album) => album.id === resJson.id);
                        if (existingIndex === -1) {
                            newVisitedAlbums.push({ id: resJson.id, name: resJson.name });
                        } else {
                            newVisitedAlbums[existingIndex] = { id: resJson.id, name: resJson.name };
                        }
                        newVisitedAlbums.sort((a, b) => a.name.localeCompare(b.name));
                        saveVisitedAlbumToStorage(newVisitedAlbums);
                    }
                    document.title = `${resJson.name} - Photos Viewer`;
                    return {
                        ...upToDateState,
                        rawImageArray: resJson,
                        imageArray: getQualityBasedImageArray(resJson, state.quality),
                        visitedAlbums: newVisitedAlbums,
                        selectedImage: null
                    };
                } else {
                    return this.setEmptyAlbumState({ error: 'Album empty or not found' });
                }
            });
        return fetchOnGoing;
    },
    setEmptyAlbumState(state, aditionalOverride = {}) {
        return Promise.resolve({
            ...state,
            currentUrl: '/',
            imageArray: {},
            rawImageArray: {},
            selectedImage: null,
            album: '',
            ...aditionalOverride
        });
    },
    toggleDarkTheme(state) {
        return Promise.resolve({
            darkThemeEnabled: !state.darkThemeEnabled,
        });
    },
    toggleSaveVisitedAlbum(state) {
        let newSaveVitedAlbum = !state.saveVisitedAlbum
        localStorageUtils.setItem('saveVisitedAlbum', newSaveVitedAlbum === false ? 0 : 1);
        return Promise.resolve({
            saveVisitedAlbum: newSaveVitedAlbum,
        });
    },
    setVisitedAlbums(state, visitedAlbums) {
        let newVisitedAlbums = state.visitedAlbums;
        if (Array.isArray(visitedAlbums)) { // Add a check to ensure visitedAlbums is an array
            newVisitedAlbums = visitedAlbums;
            saveVisitedAlbumToStorage(newVisitedAlbums);
        }
        return Promise.resolve({
            ...state,
            visitedAlbums: newVisitedAlbums,
        });
    },
    setCurrentUrl(state, url) {
        // need to be in the next micro queue otherwise some component will not be aware specially when loading specific page
        return Promise.resolve({ ...state, currentUrl: url });
    },
    setAlbum(state, album) {
        // need to be in the next micro queue otherwise some component will not be aware specially when loading specific page
        return Promise.resolve({ ...state, album: (album ? album : state.album) });
    },
    setQuality(state, {quality, byUser}) {
        let qualityAuto;
        let newQuality;
        if (quality === 'auto') {
            localStorageUtils.removeItem('quality');
            qualityAuto = true;
            newQuality = state.quality;
        } else {
            qualityAuto = !byUser;
            newQuality = quality;
            if (byUser) localStorageUtils.setItem('quality', quality);
        }
        // need to be in the next micro queue otherwise some component will not be aware specially when loading specific page
        return Promise.resolve({
            ...state,
            quality: newQuality,
            qualityAuto,
            imageArray: getQualityBasedImageArray(state.rawImageArray, quality),
        });
    },
    setSelectedImage(state, index) {
        let img = null;
        index = parseInt(index, 10);
        const imageArray = state.imageArray.photos;
        if(index !== null && imageArray.length > index) {
            img = { ...imageArray[index] };
            img.index = index;
            img.prev = index > 0 ? index - 1 : null;
            img.next = index < imageArray.length -1 ? index + 1 : null;
            const imgName = img.url.split('/');
            img.viewUrl = imgName.length > 0 ? `${PHOTOS_HOST}/view/${state.album}/${imgName[imgName.length - 1]}` : '';
        }
        return Promise.resolve({ ...state, selectedImage: img });
    },
    emptyAlbumData(state) {
        return Promise.resolve({
            ...state,
            imageArray: {},
            rawImageArray: {},
            selectedImage: null,
        });
    },
    setIsFullscreen(state, isFullscreen) {
        return Promise.resolve({ ...state, isFullscreen });
    },
    setAuth(state, {accessToken, refreshToken}) {
        return Promise.resolve({ ...state, accessToken, refreshToken });
    },
    setUser(state, user) {
        if (user?.id && user?.app_metadata) {
            const loggedUser = {
                id: user.id,
                provider: user.app_metadata.provider,
                email: user.email,
                avatar: user.user_metadata.avatar_url,
                name: user.user_metadata.name,
                full_name: user.user_metadata.full_name,
                iss: user.user_metadata.iss,
                provider_id: user.user_metadata.provider_id,
            };

            return getUserFromApi(loggedUser).then((user) => {
                const upToDateState = store.getState();
                return { ...upToDateState, user };
            });
        } else {
            return Promise.resolve({ ...state, user });
        }
    },
    setError(state, error) {
        return Promise.resolve({ ...state, error });
    }
});

