import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import { Router } from 'preact-router';
import Header from './header';
import Home from '../routes/home';
import Profile from '../routes/profile';
import NotFound from '../routes/404';
import { createGenericStateToProps } from 'utils';
import { route } from 'preact-router';
import { actions } from 'components/actions';
import { supabase, setAuthCookies } from 'authApi';

// import Home from 'async!../routes/home';
// import Profile from 'async!../routes/profile';

const mapStateToProps = createGenericStateToProps({
    currentUrl: (state) => state.currentUrl,
    imageArray: (state) => state.imageArray,
    darkThemeEnabled: (state) => state.darkThemeEnabled,
});

class App extends Component {
    componentDidMount() {
        this.checkDarkTheme();
        this.initSupabaseAuth();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.darkThemeEnabled !== this.props.darkThemeEnabled) this.checkDarkTheme();

        if (prevProps.currentUrl !== this.props.currentUrl) this.navigate(this.props.currentUrl);
    }

    checkDarkTheme() {
        this.props.darkThemeEnabled
            ? document.body.classList.add('mdc-theme--dark')
            : document.body.classList.remove('mdc-theme--dark');
    }

    navigate(path) {
        route(path);
    }

    initSupabaseAuth() {

        const handleAuthSession = (session) => {
            if (!session || !session?.access_token || !session?.user) return;

            this.props.setAuth({
                accessToken: session.access_token,
                refreshToken: session.refresh_token,
            });
            this.props.setUser(session.user);
            setAuthCookies(session);
        }

        supabase.auth.getSession().then(({ data: { session } }) => {
            handleAuthSession(session);
        })
        .catch((error) => {
            try {
                const refreshToken = document.cookie
                    .split('; ')
                    .find((row) => row.startsWith('refresh_token='))
                    .split('=')[1];
                if (refreshToken) {
                    supabase.auth.signIn({
                        refreshToken: refreshToken,
                    });
                }
            } catch (e) {
                // exception throw if cookie not found
            }
        });

        supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'SIGNED_OUT') {
                this.props.setAuth({
                    accessToken: '',
                    refreshToken: '',
                });
                this.props.setUser({});
                setAuthCookies({});
            }
        });
    }


    /** Gets fired when the route changes.
     *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
     *	@param {string} event.url	The newly routed URL
     */
    handleRoute = (e) => {
        if (this.props.currentUrl !== e.url) {
            this.props.setCurrentUrl(e.url);
        }
    };

    render(props) {
        return (
            <div id="app">
                <Header selectedRoute={this.props.currentUrl} />
                <Router onChange={this.handleRoute}>
                    <Home path="/" />
                    <Home path="/album/:album" />
                    <Profile path="/profile/" user="me" />
                    <Profile path="/profile/:user" />
                    <NotFound default />
                </Router>
            </div>
        );
    }
}

export default connect(mapStateToProps, actions)(App);
