import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import Select from 'preact-material-components/Select';
import 'preact-material-components/List/style.css';
import 'preact-material-components/Menu/style.css';
import 'preact-material-components/Select/style.css';
import style from './style';

import { createGenericStateToProps } from 'utils';
import { actions } from 'components/actions';

const mapStateToProps = createGenericStateToProps({
    imageArray: (state) => state.imageArray,
    quality: (state) => state.quality,
    qualityAuto: (state) => state.qualityAuto,
    selectedImage: (state) => state.selectedImage,
});

const QUALITY_LABEL = {
    "sd": 'SD (480p)',
    "hd": 'HD (720p)',
    "fhd": 'FHD (1080p)',
    "uhd1": 'UHD1 (2160p)',
    "uhd2": 'UHD2 (4320p)',
    "original": 'Original'
};

class QualityChanger extends Component {
    state = {
        availableQualities: []
    };

    shouldComponentUpdate(nextProps) {
        this.state.availableQualities = [];
        let qualityFound = false;
        nextProps.imageArray &&
            nextProps.imageArray.qualities &&
            nextProps.imageArray.qualities.map((quality) => {
                if (quality !== 'preview') {
                    qualityFound = qualityFound || this.props.quality === quality;
                    this.state.availableQualities.push({ value: quality, name: QUALITY_LABEL[quality] });
                }
            }) &&
            nextProps.imageArray.qualities.length > 0 &&
            this.state.availableQualities.unshift({
                value: 'auto',
                name: `Auto ${QUALITY_LABEL[this.props.quality]}`,
            });

        if (nextProps.imageArray?.qualities?.length > 0 && !qualityFound) {
            this.props.setQuality({ quality: nextProps.imageArray.qualities[0], byUser: false });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.quality !== this.props.quality) {
            this.props.selectedImage && this.props.setSelectedImage(this.props.selectedImage.index);
        }
    }

    qualityChange = (e) => {
        this.props.setQuality({ quality: e.target.value, byUser: true });
    };

    render(props, state) {
        return (
            <div class={style.rootElement}>
                {state.availableQualities.length > 1 ? (
                    <Select hintText="Quality" onChange={this.qualityChange}>
                        {state.availableQualities.map((quality) => (
                            <Select.Item
                                selected={
                                    (quality.value === props.quality && !props.qualityAuto) ||
                                    (props.qualityAuto && quality.value === 'auto')
                                }
                                value={quality.value}
                            >
                                {quality.name}
                            </Select.Item>
                        ))}
                    </Select>
                ) : undefined}
            </div>
        );
    }
}
export default connect(mapStateToProps, actions)(QualityChanger);

