import { createClient } from '@supabase/supabase-js';
const SUPABASE_URL = 'https://msuimrolsztwotjjchjz.supabase.co';
const SUPABASE_KEY =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTY0MDc2Njc4MywiZXhwIjoxOTU2MzQyNzgzfQ.CVwwUPOwfWo8ulM_HFvi52F-nS1lv4fZm_XKH8ExS_s';

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

export const setAuthCookies = (session) => {
    let supabaseToken = '';
    let supabaseRefreshToken = '';
    let ttlAccessToken = new Date();
    let ttlRefreshToken = new Date();

    if (session?.access_token) {
        supabaseToken = session.access_token;
        supabaseRefreshToken = session.refresh_token;
        ttlAccessToken = new Date(session.expires_at * 1000);
        ttlRefreshToken = new Date((session.expires_at + 86400) * 1000);
    }
    document.cookie = `access_token=${supabaseToken}; expires=${ttlAccessToken}; SameSite=Lax;`;
    document.cookie = `refresh_token=${supabaseRefreshToken}; expires=${ttlRefreshToken}; SameSite=Lax;`;
}