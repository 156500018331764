import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import TopAppBar from 'preact-material-components/TopAppBar';
// import Drawer from 'preact-material-components/Drawer';
import List from 'preact-material-components/List';
import Dialog from 'preact-material-components/Dialog';
import Switch from 'preact-material-components/Switch';
import Icon from 'preact-material-components/Icon';
import Fab from 'preact-material-components/Fab';
import Button from 'preact-material-components/Button';
import 'preact-material-components/Switch/style.css';
import 'preact-material-components/Dialog/style.css';
// import 'preact-material-components/Drawer/style.css';
import 'preact-material-components/List/style.css';
import 'preact-material-components/TopAppBar/style.css';
import 'preact-material-components/Fab/style.css';
import 'preact-material-components/Button/style.css';

import QualityChanger from 'components/qualityChanger';
import { createGenericStateToProps } from 'utils';
import { actions } from 'components/actions';
import { supabase, setAuthCookies } from 'authApi';
import style from './style';

const mapStateToProps = createGenericStateToProps({
    currentUrl: (state) => state.currentUrl,
    imageArray: (state) => state.imageArray,
    darkThemeEnabled: (state) => state.darkThemeEnabled,
    saveVisitedAlbum: (state) => state.saveVisitedAlbum,
    visitedAlbums: (state) => state.visitedAlbums,
    quality: (state) => state.quality,
    isFullscreen: (state) => state.isFullscreen,
    user: (state) => state.user,
});

class Header extends Component {
    fullsceenChangeHandler = (evt) => {
        this.props.setIsFullscreen(document.fullscreenElement !== null);
    };

    componentDidMount() {
        document.addEventListener('fullscreenchange', this.fullsceenChangeHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('fullscreenchange', this.fullsceenChangeHandler);
    }

    // closeDrawer() {
    //     this.drawer?.MDComponent?.open = false;
    // }

    // openDrawer = () => (this.drawer.MDComponent.open = true);

    openSettings = () => this.dialog.MDComponent.show();

    handleFullscreen = () => {
        if (this.props.isFullscreen === true) {
            window.document.exitFullscreen().then(() => {
                return this.props.setIsFullscreen(false);
            });
        } else {
            window.document
                .getElementById('app')
                .requestFullscreen()
                .then(() => {
                    return this.props.setIsFullscreen(true);
                });
        }
    };

    handleLogin = async () => {
        supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                redirectTo: window.location.origin,
            },
        });
    };

    handleLogout = () => {
        // confirm before logout
        if (confirm('Are you sure you want to logout?')) {
            supabase.auth
                .signOut()
                .then((_response) => {
                    setAuthCookies({});
                    //alert('Logout successful');
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    closeSettings = () => this.dialog.MDComponent.close();

    // drawerRef = (drawer) => (this.drawer = drawer);
    dialogRef = (dialog) => (this.dialog = dialog);

    goHome = () => {
        this.props.setEmptyAlbumState();
        // this.props.setAlbum('')
        // .then(() => {
        //     this.props.setCurrentUrl('/');
        // });
        // this.closeDrawer();
    };
    goToMyProfile = () => {
        this.props.setCurrentUrl('/profile');
        // this.closeDrawer();
    };

    toggleDarkTheme = () => {
        this.props.toggleDarkTheme();
    };

    saveVisitedAlbum = () => {
        this.props.toggleSaveVisitedAlbum()
        .then(() => {
            if (this.props.saveVisitedAlbum) {
                this.props.setVisitedAlbums([]);
            }
        });
    };

    onAlbumClicked = (e) => {
        this.props.setAlbum(e.target.attributes['album-id'].value).then(() => {
            this.props.setCurrentUrl(`?album=${e.target.attributes['album-id'].value}`);
        });
        this.closeSettings();
    };

    getVisitedAlbumList(album) {
        return (
            <List.Item
                class={style.albumListItem}
                album-id={album.id}
                onClick={this.onAlbumClicked}
            >
                {album.name}
            </List.Item>
        );
    }

    render(props) {
        return (
            <div>
                <TopAppBar className="topappbar">
                    <TopAppBar.Row>
                        <TopAppBar.Section align-start>
                            {/* <TopAppBar.Icon menu onClick={this.openDrawer}>
                                menu
                            </TopAppBar.Icon> */}
                            <TopAppBar.Title class={style.appTitle}>
                                <span alt="Go home" title="Go home" class={style.appLogoWrapper} onClick={this.goHome}>
                                    <Icon class={style.appLogo}>photo_camera</Icon>
                                    <span class={style.appName}>Photos Viewer</span>
                                </span>
                                {props.imageArray.name ? <span class={style.titleSeparator}> - </span> : undefined}
                                {props.imageArray.name}
                            </TopAppBar.Title>
                        </TopAppBar.Section>
                        <TopAppBar.Section align-end shrink-to-fit>
                            <QualityChanger />
                            {document.body.requestFullscreen ? (
                                <TopAppBar.Icon
                                    class={style.fullscreenButton}
                                    onClick={() => this.handleFullscreen()}
                                    title={props.isFullscreen === true ? 'Exit fullscreen' : 'Fullscreen'}
                                >
                                    {props.isFullscreen === true ? 'fullscreen_exit' : 'fullscreen'}
                                </TopAppBar.Icon>
                            ) : (
                                ''
                            )}
                            {props.user.id && props.user?.avatar ? (
                                <Fab
                                    class={
                                        props.user.err
                                            ? style.loginFab + ' ' + style.userInvalid
                                            : style.loginFab + ' ' + style.userValid
                                    }
                                    onClick={() => this.handleLogout()}
                                    alt="Logout"
                                >
                                    <img
                                        class={style.userAvatar}
                                        src={props.user.avatar}
                                        alt={props.user.full_name}
                                        crossorigin="crossorigin"
                                        referrerpolicy="no-referrer"
                                    />
                                </Fab>
                            ) : (
                                <Fab class={style.loginFab} onClick={() => this.handleLogin()} alt="Login with Google">
                                    <Icon>account_circle</Icon>
                                </Fab>
                            )}
                            <TopAppBar.Icon class={style.settingButton} onClick={this.openSettings}>
                                settings
                            </TopAppBar.Icon>
                        </TopAppBar.Section>
                    </TopAppBar.Row>
                </TopAppBar>
                {/* <Drawer modal ref={this.drawerRef}>
                    <Drawer.DrawerContent>
                        <Drawer.DrawerItem selected={props.currentUrl === '/'} onClick={this.goHome}>
                            <List.ItemGraphic>home</List.ItemGraphic>
                            Home
                        </Drawer.DrawerItem>
                        <Drawer.DrawerItem selected={props.currentUrl === '/profile'} onClick={this.goToMyProfile}>
                            <List.ItemGraphic>account_circle</List.ItemGraphic>
                            Profile
                        </Drawer.DrawerItem>
                    </Drawer.DrawerContent>
                </Drawer> */}
                <Dialog
                    ref={this.dialogRef}
                    class={style.settingDialog}
                    BackdropProps={{ style: { backgroundColor: 'transparent', opacity: 1 } }}
                >
                    <Dialog.Header>Settings</Dialog.Header>
                    <Dialog.Body class={style.settingDialogBody}>
                        <div class={style.settingItem}>
                            <span>
                                Enable dark theme{' '}
                                <Switch onClick={this.toggleDarkTheme} checked={props.darkThemeEnabled} />
                            </span>
                        </div>
                        <div class={`${style.settingItem} ${style.loggedUserSetting}`}>
                            {props.user.id && props.user?.avatar ? (
                                <div class={style.UserSettingContainer}>
                                    <div class={style.loginFabSettingContainer}>
                                        <Fab
                                            class={
                                                props.user.err
                                                    ? style.loginFab + ' ' + style.userInvalid
                                                    : style.loginFab + ' ' + style.userValid
                                            }
                                            onClick={() => this.handleLogout()}
                                            alt="Logout"
                                        >
                                            <img
                                                class={style.userAvatar}
                                                src={props.user.avatar}
                                                alt={props.user.full_name}
                                                crossorigin="crossorigin"
                                                referrerpolicy="no-referrer"
                                            />
                                        </Fab>
                                        <span class={style.loginFabSettingContainerLabel}>
                                            &nbsp;Log as {props.user.full_name}
                                            <Button onClick={() => this.handleLogout()} alt="Logout">
                                                Logout
                                            </Button>
                                        </span>
                                    </div>
                                    {props.user.err ? (
                                        <div class={style.loginFabSettingContainerLabel}>
                                            Unknown user, please ask administrator for validation
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                <div class={style.loginFabSettingContainer}>
                                    <Fab onClick={() => this.handleLogin()} alt="Login with Google">
                                        <Icon>account_circle</Icon>
                                    </Fab>
                                    <span class={style.loginFabSettingContainerLabel}>
                                        &nbsp;
                                        <Button onClick={() => this.handleLogin()} alt="Login with Google">
                                            Login with Google
                                        </Button>
                                    </span>
                                </div>
                            )}
                        </div>
                        <div class={style.settingItem}>
                            <span>
                                Save visited albums{' '}
                                <Switch onClick={this.saveVisitedAlbum} checked={props.saveVisitedAlbum} />
                            </span>
                            {props.saveVisitedAlbum ? (
                                <List class={style.albumList}>
                                    {props.visitedAlbums.map((album) => this.getVisitedAlbumList(album))}
                                </List>
                            ) : undefined}
                        </div>
                    </Dialog.Body>
                    <Dialog.Footer>
                        <Dialog.FooterButton accept>OK</Dialog.FooterButton>
                    </Dialog.Footer>
                </Dialog>
            </div>
        );
    }
}

export default connect(mapStateToProps, actions)(Header);
