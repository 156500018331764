import './style';
import App from './components/app';
import { store } from './components/store';
import { Provider } from 'unistore/preact';

export default () => (
    <Provider store={store}>
        <App />
    </Provider>
);
