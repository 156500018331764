
export const createGenericStateToProps = (namedSelectors) => {
    const selectors = Object.keys(namedSelectors).map((name) => {
        return { name, select: namedSelectors[name] };
    });

    return (state) => {
        const namedValues = {};
        selectors.forEach(({ name, select }) => {
            namedValues[name] = select(state);
        });
        return namedValues;
    };
};
export const localStorageUtils = (() => {
    if (typeof window !== 'undefined') {
        return window.localStorage
    }
    return {
        getItem(item) {return item;},
        setItem(item, value) {return}
    }
})();
// export const utils = { localStorageUtils: localStorageUtils, createGenericStateToProps: createGenericStateToProps };